// MAIN APP STYLES
@import "App.styles";

// PAGES STYLES
@import "pages/pages.styles";

// COMPONENTS STYLES
@import "components/components.styles";

body {
  overflow-x: hidden;
  margin: 0;
}